<template>
  <page title="Not Found" />
</template>
<script lang="ts">
import Page from '../../components/Page/index.vue'

export default {
  name: 'NotFound',
  components: { Page },
}
</script>
