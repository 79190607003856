<template>
  <page title="Not Allowed" />
</template>
<script lang="ts">
import Page from '../../components/Page/index.vue'

export default {
  name: 'NotAllowed',
  components: { Page },
}
</script>
